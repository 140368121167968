import axios from 'axios';
import { setErrorMessage, setTestResult } from '../store/slices/stateSlice';
import { isEmailVerified } from './user/service';

const RUN_TESTS_ENDPOINT = "/test-api" 
const API_HOST = "https://site--server--bx6y7bhy4vcf.code.run" 
const USER_LIMITS_ENDPOINT = "/user-limits" 
export const runTest = async (testRequest, dispatch) => {
    try {
        if(!isEmailVerified()) throw Error("Please verify your email before running tests.")
        const testResults = await axios({
            method: 'POST',
            url: API_HOST + RUN_TESTS_ENDPOINT, // Corrected the environment variable
            headers: {
                Authorization: testRequest.token, // Corrected the headers
            },
            data: testRequest.payload,
        });
        dispatch(setTestResult(testResults.data));
        dispatch(setErrorMessage(""));
    } catch (error) {
        let message = "Unknown error"
        if(error.response && error.response.data) {
            message = error.response.data.error
        }else if(error.message) message = error.message
        console.log("runTest | set error | message: ", message)
        dispatch(setErrorMessage(message));
    }
};

export const runUserLimitsRequest = async (token, dispatch) => {
    try {
        const userLimits = await axios({
            method: 'GET',
            url: API_HOST+USER_LIMITS_ENDPOINT,
            headers: {
                Authorization: token,
            },
        });
        return userLimits.data;
    } catch (error) {
        console.log("runUserLimitsRequest | error: ", error)
        let message = "Unknown error"
        if(error.response && error.response.data) message = error.response.data.message
        dispatch(setErrorMessage(error.message)); // Dispatching the action here
    }
}