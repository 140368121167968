import { useEffect, useState } from 'react';
import { addToDiscoveryResult, setDiscoveryCooldown, setDiscoveryPreloading, setDiscoveryRunning, setDiscoverySummary, setErrorMessage } from '../../store/slices/stateSlice';
import { getUserToken } from '../auth/firebase';
import { isEmailVerified } from '../user/service';
const WS_MSG_TYPE_SINGLE_DISCOVERY = "discovery"
const WS_MSG_TYPE_DISCOVERY_SUMMARY = "summary"
const WS_MSG_TYPE_DISCOVERY_ERROR = "err"
const WS_MSG_TYPE_DISCOVERY_START = "start"

export const useWebSocket = (wsUrl, apiHost, shouldConnect, dispatch) => {
    const [data, setData] = useState([]);

    useEffect(() => {
        if (!shouldConnect) return;
        if(!isEmailVerified()){
            dispatch(setErrorMessage("Please verify your email before running tests"));
            return;
        } 

        dispatch(setErrorMessage(""))
        dispatch(setDiscoveryPreloading(true))

        // start a secure websocket connection (wss instead of ws)
        // const secureWsUrl = wsUrl.replace(/^ws:\/\//i, "wss://");
        const ws = new WebSocket(wsUrl);

        ws.onmessage = (event) => {
            const messageData = JSON.parse(event.data);

            if(messageData.type == WS_MSG_TYPE_SINGLE_DISCOVERY) {
                dispatch(setDiscoveryPreloading(false))
                dispatch(addToDiscoveryResult(messageData))
                setData((prevData) => [...prevData, messageData]);
            }else if(messageData.type == WS_MSG_TYPE_DISCOVERY_SUMMARY) {
                dispatch(setDiscoveryPreloading(false))
                dispatch(setDiscoveryRunning(false))
                dispatch(setDiscoverySummary(messageData))
            }else if(messageData.type == WS_MSG_TYPE_DISCOVERY_ERROR) {
                dispatch(setDiscoveryPreloading(false))
                dispatch(setDiscoveryRunning(false))
                dispatch(setErrorMessage(messageData.message))
            }else if(messageData.type == WS_MSG_TYPE_DISCOVERY_START) {
                // note: can do something on first response
            }
            else {
                dispatch(setDiscoveryPreloading(false))
                dispatch(setDiscoveryRunning(false))
                dispatch(setErrorMessage("Uknown response"))
            }
        };

        ws.onopen = async () => {
            const token = await getUserToken()
            if(token) {
                const reqPayload = { action: 'discover', host: apiHost, token:token }
                ws.send(JSON.stringify(reqPayload));
            }
        };

        ws.onclose = () => {
            // console.log('WebSocket Disconnected');
        };

        ws.onerror = (error) => {
            // console.log('WebSocket Disconnected | error: ', error);
        };

        return () => {
            ws.close();
        };
    }, [shouldConnect]); // Only re-run the effect if shouldConnect changes

    return data;
};

export default useWebSocket;