import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { checkEmailRegex } from '../util/sanitizer';
import { sendPasswordReset } from '../core/auth/firebase';
import { selectAuthError, setAuthError } from '../store/slices/stateSlice';
import { logInAndSetup } from '../core/user/service';

function LoginForm({ onSignupRedirect }) {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [resetMessage, setResetMessage] = useState('');
  const [loading, setLoading] = useState(false);
  let error = useSelector(selectAuthError);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const result = await logInAndSetup("CREDENTIALS", email, password, dispatch);
    setLoading(false);
    // The navigation will be handled by the parent component through a callback.
  };

  const handlePasswordReset = async () => {
    try {
      if (!checkEmailRegex(email)) setAuthError("Invalid email address.");
      else {
        await sendPasswordReset(email);
        setResetMessage('Password reset email sent. Please check your inbox.');
        setAuthError("");
      }

    } catch (error) {
      setResetMessage('Failed to send password reset email.');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <input
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Email"
        required
      />
      <input
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        placeholder="Password"
        required
      />
      <div className="link-container">
        {!resetMessage && (
          <p className="link-button" onClick={handlePasswordReset}>
            Forgot Password?
          </p>
        )}

      </div>

      {resetMessage && <p className="info-txt">{resetMessage}</p>}
      <button className="wbutton main" type="submit" disabled={loading}>
        {loading ? 'Loading...' : 'Login'}
      </button>
      {error && <p className="error-msg">{error}</p>}    </form>
  );
}

export default LoginForm;
