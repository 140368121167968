import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signInWithPopup, sendPasswordResetEmail, GoogleAuthProvider, getAuth, getIdToken, signOut } from 'firebase/auth';


export const getUserToken = async () => {
    try {
        const auth = getAuth();
        const user = auth.currentUser;

        if (user) {

            const token = await getIdToken(user);
            return token;

        } else throw Error("No user is currently logged in")
    } catch (error) {
        console.error("getUserToken | error:", error);
        return null;
    }
};

export const getUser = () => {
    try {
        const auth = getAuth();
        const user = auth.currentUser;

        if (user) return user
        else throw Error("No user is currently logged in")
    } catch (error) {
        console.error("getUser | error:", error);
        return null;
    }
}

export const getUserEmail = () => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (user) {
        try {
            return user.email;
        } catch (error) {
            console.error("Error getting user email:", error);
            return null;
        }
    } else {
        console.log("No user is currently logged in.");
        return null;
    }
}

export const signOutUser = async () => {
    const auth = getAuth();
    await signOut(auth)
}

export const sendPasswordReset = async (email) => {
    try {
        await sendPasswordResetEmail(email)
    } catch (error) {
        console.error("firebase | sendPasswordReset | error: ", error)
        return false;
    }
}

export const signInWithCredentials = async (email, password) => {
    const auth = getAuth();
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    return userCredential.user
}

export const signUpWithCredentials = async (email, password) => {
    const auth = getAuth();
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    return userCredential.user
}

export const signInWithGoogle = async () => {
    try {
        const provider = new GoogleAuthProvider();
        const auth = getAuth();
        const result = await signInWithPopup(auth, provider)
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        const user = result.user;
        // IdP data available using getAdditionalUserInfo(result)
        return user
    } catch (error) {
        console.error("firebase | signInWithGoogle | error: ", error)
        return false;
    }
}

