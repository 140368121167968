import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../auth/auth-context';

function PublicRoute({ children }) {
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  if (currentUser) {
    navigate('/dashboard');
    return null;
  }
  
  return children;
}

export default PublicRoute;
