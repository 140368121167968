import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Dashboard from './Dashboard';
import Auth from './views/AuthView';
import PrivateRoute from './core/router/private-router';
import PublicRoute from './core/router/public-router';
import { AuthProvider } from './core/auth/auth-context'; // Import AuthProvider from your auth-context.js
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useDispatch } from 'react-redux';
import { cleanUser, setupUser } from './core/user/service';
import RootRedirect from './core/router/root-redirect';

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, user => {
      if (user) setupUser(dispatch, user)
      else {
        cleanUser(dispatch)
        console.log("User logged out.")
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();

  }, [dispatch]);

  return (
    <AuthProvider> {/* Wrapping the entire application with AuthProvider */}
      <Router>
        <Routes>
          <Route path="/" element={<RootRedirect />} />
          <Route
            path="/authenticate"
            element={
              <PublicRoute>
                <Auth />
              </PublicRoute>
            }
          />
          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          {/* other routes */}
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;