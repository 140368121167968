import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from './store/store'; // make sure the path to your store is correct
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}> {/* Wrap your App with the Provider and pass the store */}
      <App />
    </Provider>
  </React.StrictMode>
);

const firebaseConfig = {
  apiKey: "AIzaSyA5V1K47xCBktRcHKctaBsl9SWoS6d7dco",
  authDomain: "loader-fff7a.firebaseapp.com",
  projectId: "loader-fff7a",
  storageBucket: "loader-fff7a.appspot.com",
  messagingSenderId: "552543472145",
  appId: "1:552543472145:web:07c18942f2c4941e503fb0",
  measurementId: "G-JGK5JP944H"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);

const analytics = getAnalytics(app);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();