import React, { useContext, useState, useEffect } from 'react';
// Importing required methods and modules in a modular way
import { getAuth, onAuthStateChanged } from 'firebase/auth';

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const auth = getAuth(); // Getting the Auth instance
  
  useEffect(() => {
    // Using onAuthStateChanged with the modular auth instance
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
    });

    // Cleanup subscription on component unmount
    return unsubscribe;
  }, [auth]);

  const contextValue = {
    currentUser,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
}
