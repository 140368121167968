// RootRedirect.js
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../auth/auth-context';

function RootRedirect() {
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  useEffect(() => {
    if (currentUser) {
      navigate('/dashboard');
    } else {
      navigate('/authenticate');
    }
  }, [currentUser, navigate]);

  return null;
}

export default RootRedirect;
