export const sanitizeInput = (input) => {
  // Remove "/" from the start and end of the string
  let sanitizedInput = input.replace(/^\/+|\/+$/g, '');

  // Encode special characters to prevent XSS attacks
  sanitizedInput = sanitizedInput.replace(/&/g, '&amp;')
                                .replace(/</g, '&lt;')
                                .replace(/>/g, '&gt;')
                                .replace(/"/g, '&quot;')
                                .replace(/'/g, '&#x27;');
  
  // Limit the length of the input string to prevent excessively long strings
  const MAX_LENGTH = 255;
  if (sanitizedInput.length > MAX_LENGTH) {
    sanitizedInput = sanitizedInput.substring(0, MAX_LENGTH);
  }
  
  return sanitizedInput;
}

export const checkEmailRegex = (email) => {
    // Regular expression to match a standard email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    return emailRegex.test(email);
}