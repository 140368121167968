import React, { useState } from 'react';
import LoginForm from '../components/loginForm';
import SignupForm from '../components/signupForm';
import { useNavigate } from 'react-router-dom';
import '../assets/styles/authview.css';
// import '../assets/styles/login.css'; 
import '../assets/styles/index.css';
import { logInAndSetup } from '../core/user/service';
import { useDispatch } from 'react-redux';

function AuthView() {
  const dispatch = useDispatch();

  const [activeView, setActiveView] = useState('login');
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };

  const toggleView = () => {
    setActiveView(activeView === 'login' ? 'signup' : 'login');
  };

  const handleGoogleSignIn = async () => {
    setLoading(true);
    const result = await logInAndSetup("GOOGLE", null, null, dispatch)
    if (result) navigate('/dashboard');
    setLoading(false);
  }

  return (
    <div className="auth-container">
      <div className="logo-container">
        <div className="main-logo"></div>
      </div>
      <div className="auth-info">
        <h1>Secure Your API and Code.</h1>
        <p>Advisor offers a straightforward approach to complex security needs. <br></br>Run load, penetration, and code vulnerability tests with ease.</p>
        {/* Static image and intro text here */}
      </div>
      <div className="auth-form">
        {activeView === 'login' ? (
          <LoginForm onSignupRedirect={() => handleNavigation('/signup')} />
        ) : (
          <SignupForm onLoginRedirect={() => handleNavigation('/login')} />
        )}
        <p onClick={toggleView} className="link-button">
          {activeView === 'login' ? 'Need an account? Sign Up' : 'Already have an account? Login'}
        </p>

        <hr className="separator-line"></hr>
        <p>or</p>
        <div onClick={handleGoogleSignIn} className="google-signin"></div>
      </div>
    </div>
  );
}

export default AuthView;
