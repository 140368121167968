import { createSlice } from '@reduxjs/toolkit';

export const stateSlice = createSlice({
  name: 'auth',
  initialState: {
    userEmail: null,
    testResult: null,
    errorMessage: null,
    maxConcurrency: 1,
    userLimits: {
      maxConcurrency:0,
      maxDiscoveries:0,
      maxTests:0,
      maxDiscoveriesPerSession:0
    },
    authError: null,
    userFirstName: "",
    emailVerifiedMsg: "",
    discoveryResult: [],
    discoverySummary: {
      attempts: 0,
      proResponses: 0,
      accResponses: 0,
      existingResponses: 0,
      errResponses:0,
    },
    discoveryCooldown: 0,
    discoveryRunning: false,
    discoveryPreloading: false,
  },
  reducers: {
    setUserEmail: (state, action) => {
      state.userEmail = action.payload;
    },
    setTestResult: (state, action) => {
      state.testResult = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    },
    setMaxConcurrency: (state, action) => {
      state.maxConcurrency = action.payload;
    },
    setAuthError: (state, action) => {
      state.authError = action.payload;
    },
    setName: (state, action) => {
      state.userFirstName = action.payload;
    },
    setDiscoveryResult: (state, action) => {
      state.discoveryResult = action.payload;
    },
    addToDiscoveryResult: (state, action) => {
      state.discoveryResult.push(action.payload);
    },
    setDiscoverySummary: (state, action) => {
      state.discoverySummary = action.payload;
    },
    setDiscoveryRunning: (state, action) => {
      state.discoveryRunning = action.payload;
      if(action.payload === false) state.discoveryCooldown = 5;
    },
    setUserLimits: (state, action) => {
      state.userLimits = action.payload;
    },
    setDiscoveryCooldown: (state, action) => {
      state.discoveryCooldown = action.payload;
    },
    setDiscoveryPreloading: (state, action) => {
      state.discoveryPreloading = action.payload;
    },
    setEmailVerifiedMsg: (state, action) => {
      state.emailVerifiedMsg = action.payload;
    }
  },
});

export const { setEmailVerifiedMsg, setDiscoveryPreloading, setDiscoveryCooldown, setUserLimits, setDiscoverySummary, setDiscoveryRunning, addToDiscoveryResult, setDiscoveryResult, setName, setUserEmail, setTestResult, setErrorMessage, setMaxConcurrency, setAuthError } = stateSlice.actions;

export const selectUserEmail = (state) => state.auth.userEmail;
export const selectTestResult = (state) => state.auth.testResult;
export const selectErrorMessage = (state) => state.auth.errorMessage;
export const selectMaxConcurrency = (state) => state.auth.maxConcurrency;
export const selectAuthError = (state) => state.auth.authError;
export const selectName = (state) => state.auth.userFirstName;
export const selectDiscoveryResult = (state) => state.auth.discoveryResult;
export const selectDiscoverySummary = (state) => state.auth.discoverySummary;
export const selectDiscoveryRunning = (state) => state.auth.discoveryRunning;
export const selectUserLimits = (state) => state.auth.userLimits;
export const selectDiscoveryCooldown = (state) => state.auth.discoveryCooldown;
export const selectDiscoveryPreloading = (state) => state.auth.discoveryPreloading;
export const selectEmailVerifiedMsg = (state) => state.auth.emailVerifiedMsg;

export default stateSlice.reducer;
