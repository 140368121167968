import { sendEmailVerification } from "firebase/auth";
import {  setAuthError, setDiscoveryRunning, setEmailVerifiedMsg, setMaxConcurrency, setName, setUserEmail, setUserLimits } from "../../store/slices/stateSlice";
import { getUser, getUserToken, signInWithCredentials, signInWithGoogle, signOutUser, signUpWithCredentials } from "../auth/firebase";
import { runUserLimitsRequest } from "../controller";

export const logOut = async (dispatch) => {
    try {
        await signOutUser()
        dispatch(setUserEmail("")); // Dispatching the action here
    } catch (error) {
        console.log("logout | error:", error)
    }
}

const ALLOWED_LOGIN_METHODS = ["GOOGLE", "CREDENTIALS"]
export const logInAndSetup = async (method, email, password, dispatch) => {
    try {
        if (!ALLOWED_LOGIN_METHODS.includes(method)) throw new Error("Invalid login method")
        dispatch(setAuthError("")); // Dispatching the action here
        let user
        if (method === "CREDENTIALS") user = await signInWithCredentials(email, password);
        else if (method === "GOOGLE") user = await signInWithGoogle();
        return true
    } catch (error) {
        console.log("logInAndSetup | error:", error)
        let message = "Unknown error"
        if (error && error.message.includes("auth/invalid-login-credentials")) message = "Invalid credentials."
        dispatch(setAuthError(message)); // Dispatching the action here
        return false
    }
}

export const setupUser = async (dispatch, user) => {
    try {
        dispatch(setUserEmail(user.email));
        if (!isEmailVerified()) dispatch(setEmailVerifiedMsg("Verify email"));
        else userEmailVerificationDetected(dispatch, user)

        if (user.displayName) dispatch(setName(user.displayName.split(" ")[0]));

        const token = await getUserToken();
        if (token) {
            const limits = await runUserLimitsRequest(token, dispatch);
            if (limits) {
                dispatch(setMaxConcurrency(Number(limits.maxConcurrency))); // Dispatching the action here
                dispatch(setUserLimits(limits)); // Dispatching the action here
            }
        }
    } catch (error) {
        console.error("setupUser | error: ", error)
    }
}

export const cleanUser = async (dispatch) => {
    dispatch(setUserEmail("")); // Dispatching the action here
    dispatch(setMaxConcurrency(1)); // Dispatching the action here
    dispatch(setDiscoveryRunning(false)) // stop any discoveries
}

export const registerWithCredentials = async (email, password, dispatch) => {
    try {
        // todo: test password check
        if (!isStrongPassword(password)) throw new Error("Password is not strong enough");
        const user = await signUpWithCredentials(email, password);
        await sendEmailVerificationLink(user)
        dispatch(setEmailVerifiedMsg("Verify, check email"));
        localStorage.setItem('emailVerified', false);
        dispatch(setUserEmail(email));
    } catch (error) {
        console.log("registerWithCredentials | error:", error)
        let message = "Unknown error"
        if (error && error.message.includes("auth/email-already-in-use")) message = "Email already in use."
        else if (error && error.message.includes("Password")) message = error.message;
        dispatch(setAuthError(message)); // Dispatching the action here
        return false
    }
}

export const isEmailVerified = () => {
    try {
        const user = getUser()
        return user.emailVerified
    } catch (error) {
        console.log("isEmailVerified | error:", error)
        return false
    }
}

export const sendEmailVerificationLink = async (user) => {
    try {
        let userForSure = user
        if (!userForSure) userForSure = getUser()
        await sendEmailVerification(userForSure)
    } catch (error) {
        console.log("sendEmailVerificationLink | error:", error)
    }
}

// NB! only call when you are certain user email is verified.
export const userEmailVerificationDetected = async (dispatch, user) => {
    let emailPreviouslyVerified = localStorage.getItem('emailVerified');

    if(emailPreviouslyVerified === "false") {
        // email verified for the first time, refreshing user token and running setup again.
        // reload to get new token where email verification is set to true.
        await user.getIdToken(/* forceRefresh */ true)
        setupUser(dispatch, user)
    }
    localStorage.setItem('emailVerified', true);
    dispatch(setEmailVerifiedMsg(""));
}

const isStrongPassword = (password) => {
    const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");
    return strongRegex.test(password);
}