// SecondTabContent.js
import React, { useState, useEffect } from 'react';
import { strings } from '../assets/strings'
import { useDispatch, useSelector } from 'react-redux';
import { selectDiscoveryCooldown, selectDiscoveryPreloading, selectDiscoveryResult, selectDiscoveryRunning, selectDiscoverySummary, selectEmailVerifiedMsg, selectErrorMessage, selectUserLimits, setDiscoveryCooldown, setDiscoveryResult, setDiscoveryRunning } from '../store/slices/stateSlice';
import { useWebSocket } from '../core/socket/controller';
import '../assets/styles/discover.css'; // Importing the SCSS file here

function DiscoverTab() {
  const dispatch = useDispatch();

  let userLimits = useSelector(selectUserLimits);
  let errorMessage = useSelector(selectErrorMessage);
  let discoveryData = useSelector(selectDiscoveryResult);
  let discoverySummary = useSelector(selectDiscoverySummary);
  let discoveryRunning = useSelector(selectDiscoveryRunning);
  let discoveryCooldown = useSelector(selectDiscoveryCooldown);
  let discoveryPreloading = useSelector(selectDiscoveryPreloading);
  let emailVerifiedMsg = useSelector(selectEmailVerifiedMsg);

  const [apiHost, setApiHost] = useState('');
  const [warningAcknowledged, setWarningAcknowledged] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [showContact, setShowContact] = useState(false);
  const [loading, setLoading] = useState(false);
  // const WS_URL = process.env.REACT_APP_WS_URL

  const WS_URL = "wss://site--server--bx6y7bhy4vcf.code.run"
  useWebSocket(WS_URL, apiHost, discoveryRunning, dispatch); // this does the discovery logic.

  const [methodFilter, setMethodFilter] = useState('');
  const [statusCodeFilter, setStatusCodeFilter] = useState('');
  const [classificationFilter, setClassificationFilter] = useState('');
  const [urlSearch, setUrlSearch] = useState('');

  const filteredData = discoveryData
    .filter((item) => item && item.classification) // filter out items with no data or classification
    .filter((item) => !methodFilter || item.method === methodFilter)
    .filter((item) => !statusCodeFilter || item.statusCode === Number(statusCodeFilter))
    .filter((item) => !classificationFilter || item.classification === classificationFilter)
    .filter((item) => !urlSearch || item.url.includes(urlSearch));


  const handleRunDiscovery = async () => {
    if (discoveryRunning) {
      dispatch(setDiscoveryRunning(false));
    } else {
      dispatch(setDiscoveryResult([]));
      dispatch(setDiscoveryRunning(true));
    }
  };


  const acknowledgeWarning = () => {
    setWarningAcknowledged(true);
    sessionStorage.setItem('warningAcknowledged', 'true');
    setShowWarning(false);
  };

  const openWarning = () => {
    setShowWarning(true);
  };

  // todo: merge with the same logic in Dashboard.js
  const toggleContact = () => {
    if (showContact) setShowContact(false);
    else setShowContact(true);
  };


  useEffect(() => {
    let cooldownTimer;
    if (discoveryCooldown > 0) {
      cooldownTimer = setTimeout(() => {
        // Calculate the new cooldown value inside useEffect
        const newCooldownValue = discoveryCooldown - 1;
        // Dispatch the action with the new value as the payload
        dispatch(setDiscoveryCooldown(newCooldownValue));
      }, 1000);
    }

    return () => clearTimeout(cooldownTimer);
  }, [discoveryCooldown, dispatch]); // Include dispatch in the dependency array


  return (
    <div className="discover-container">
      <div className="inputs">
        <p>Discover API endpoints and their protection levels.</p>
        <input
          type="text"
          value={apiHost}
          onChange={(e) => setApiHost(e.target.value)}
          placeholder="API Host (e.g. https://api.example.com)"
        />

        {warningAcknowledged? (
          <button
            className={`wbutton run-tests-btn ${discoveryRunning}`}
            onClick={handleRunDiscovery}
            disabled={emailVerifiedMsg || !apiHost || loading || discoveryCooldown > 0 || discoveryPreloading}
          >
            {discoveryPreloading ? 'Starting up...' : discoveryCooldown > 0 ?
              `Start Discovery (Cooldown: ${discoveryCooldown}s)` :
              (discoveryRunning ? 'Stop' : 'Start') + ' Discovery'}
          </button>
        ) : (
          <button className="wbutton warning" onClick={openWarning}>
            Read Warning
          </button>
        )}


        {showWarning && (
          <div className="popup">
            <div dangerouslySetInnerHTML={{ __html: strings.en.usage_warn }}></div>
            <button onClick={acknowledgeWarning} className="wbutton secondary">Acknowledge</button>
          </div>
        )}
        {errorMessage ? <p style={{ color: "red" }}>{errorMessage}</p> : null}
      </div>
      {showContact && (
        <div className="popup">
          <div className="popup-content">Email <strong>contact@wenture.io</strong> for quota increase.</div>
          <button onClick={toggleContact} className="wbutton secondary">Close</button>
        </div>
      )}

      <div className="results">
        <div className="test-summary">
          <h2>Test Results</h2>
          <p>
            <strong>Nr of attempts:</strong> {discoveryData.length} / {userLimits.maxDiscoveriesPerSession}
            <span className="link-button" onClick={toggleContact}> Increase attempts</span>

          </p>

          {discoverySummary.errResponses && (<div>
            <p><b>TESTED ENDPOINTS</b></p>
            <p>
              <strong>Protected:</strong> {discoverySummary.proResponses}
            </p>
            <p>
              <strong>Open (public):</strong> {discoverySummary.accResponses}
            </p>
            <p>
              <strong>Existing</strong><i> (not accessible):</i> {discoverySummary.existingResponses}
            </p>
          </div>)}

        </div>
      </div>

      <div className="discover-container">
        {/* todo: extract table to separate component. */}
        <div className="filters">
          <select className="filter" onChange={(e) => setMethodFilter(e.target.value)}>
            <option value="">Filter by Method</option>
            <option value="GET">GET</option>
            <option value="POST">POST</option>
            <option value="DELETE">DELETE</option>
            <option value="PUT">PUT</option>
          </select>
          <input
            className="filter filter-search"
            type="text"
            value={urlSearch}
            onChange={(e) => setUrlSearch(e.target.value)}
            placeholder="Search URLs"
          />

          <select className="filter" onChange={(e) => setStatusCodeFilter(e.target.value)}>
            <option value="">Filter by Status Code</option>
            <option value="200">200</option>
            <option value="401">401</option>
            <option value="404">404</option>
            <option value="500">500</option>
            <option value="503">503</option>
          </select>



          <select className="filter" onChange={(e) => setClassificationFilter(e.target.value)}>
            <option value="">Filter by Classification</option>
            <option value="accessible">ACCESSIBLE</option>
            <option value="protected">PROTECTED</option>
            <option value="erroneous">ERRONEOUS</option>
            <option value="existing">EXISTING</option>
          </select>
        </div>

        <table className="discover-table">
          <thead>
            <tr>
              <th>Attempt</th>
              <th>Importance</th>
              <th>Method</th>
              <th>Url</th>
              <th>Status Code</th>
              <th>Classification</th>
            </tr>
          </thead>
          <tbody>
            {filteredData
              .filter((item) => item && item.classification) // filter out items with no data or classification
              .map((item, index) => (
                <tr key={index}>
                  <td>{item.attempt}</td>
                  <td>
                    <span className={`status-dot ${item.classification}`}></span>
                  </td>
                  <td><span className={`method-cookie ${item.method}`}>{item.method}</span></td>
                  <td className="url-cell"><span>{item.url}</span></td>
                  <td>{item.statusCode}</td>
                  <td>
                    <span className={`auth-cookie ${item.classification}`}>
                      {item.classification.toUpperCase()}
                    </span>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default DiscoverTab;
