import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectDiscoveryRunning, selectEmailVerifiedMsg, selectErrorMessage, selectMaxConcurrency, selectName, selectTestResult } from './store/slices/stateSlice';
import { runTest } from './core/controller';
import { useDispatch } from 'react-redux';
import { sanitizeInput } from './util/sanitizer';
import { getUserToken } from './core/auth/firebase';
import { Line, Bar } from 'react-chartjs-2'; 
import { Chart, registerables } from 'chart.js';
import { logOut } from './core/user/service';
import { strings } from './assets/strings'
import DiscoverTab from './views/Discover';
import './assets/styles/dashboard.css'; 
import './assets/styles/index.css'; 
Chart.register(...registerables);

function Dashboard() {

  const dispatch = useDispatch();

  const userName = useSelector(selectName);
  const maxConcurrency = useSelector(selectMaxConcurrency);
  const testResults = useSelector(selectTestResult);
  const emailVerifiedMsg = useSelector(selectEmailVerifiedMsg);
  let errorMessage = useSelector(selectErrorMessage);
  let discoveryRunning = useSelector(selectDiscoveryRunning);
  let testSummary = null
  if (testResults && testResults.summary) testSummary = testResults.summary

  const [apiHost, setApiHost] = useState('');
  const [endpointUrl, setEndpointUrl] = useState('');
  const [requestMethod, setRequestMethod] = useState('GET');
  const [concurrentUsers, setConcurrentUsers] = useState(1);
  const [showLog, setShowLog] = useState(false); // New state to toggle log visibility
  const [copySuccess, setCopySuccess] = useState(''); // State to handle copy success message
  const [selectedStatusCode, setSelectedStatusCode] = useState('200'); // State to handle selected status code
  const [showInfo, setShowInfo] = useState(true); // State to handle selected status code
  const [warningAcknowledged, setWarningAcknowledged] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [cooldown, setCooldown] = useState(0);
  const [showContact, setShowContact] = useState(false);
  const [activeTab, setActiveTab] = useState('tab1');
  const [loading, setLoading] = useState(false);
  const [chartKey, setChartKey] = useState(0);

  const handleRunTests = async () => {
    setLoading(true);
    const token = await getUserToken()
    const testRequest = {
      token: token,
      payload: {
        "api": sanitizeInput(apiHost),
        "endpoint": sanitizeInput(endpointUrl),
        "method": requestMethod,
        "connections": concurrentUsers,
        "successCode": selectedStatusCode,
        "testMethod": requestMethod
      }
    }
    await runTest(testRequest, dispatch)
    setLoading(false);
    // Toggling chartKey to force chart redraw
    setChartKey(prevKey => prevKey + 1);
    setCooldown(10);
  };

  useEffect(() => {
    let cooldownTimer;
    if (cooldown > 0) {
      cooldownTimer = setTimeout(() => {
        setCooldown(prevCooldown => prevCooldown - 1);
      }, 1000);
    }

    return () => clearTimeout(cooldownTimer);
  }, [cooldown]);

  const acknowledgeWarning = () => {
    setWarningAcknowledged(true);
    sessionStorage.setItem('warningAcknowledged', 'true');
    setShowWarning(false);
  };

  const openWarning = () => {
    setShowWarning(true);
  };

  const toggleContact = () => {
    if (showContact) setShowContact(false);
    else setShowContact(true);
  };

  const handleLogout = () => {
    // todo:  Add your logout logic here
    logOut(dispatch)
  };

  // Function to handle log visibility
  const handleToggleLog = () => {
    setShowLog((prevShowLog) => !prevShowLog);
  };

  // Function to calculate the color based on the slider value
  const calculateThumbColor = (value, max) => {
    const percentage = value / max;
    const red = Math.min(255, Math.round(76 + 179 * percentage)); // Transitioning to dark red
    const green = Math.round(175 - 175 * percentage); // Starting from 175 (4CAF50 green component)
    return `rgb(${red}, ${green}, 80)`; // 80 is the blue component of the starting color
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(JSON.stringify(testResults.details, null, 2))
      .then(() => {
        setCopySuccess('Copied to clipboard!');
        setTimeout(() => setCopySuccess(''), 2000); // Remove the success message after 2 seconds
      })
      .catch(err => console.error('Failed to copy text: ', err));
  };


  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleRunTests();
    }
  };

  const [summaryChartData, setSummaryChartData] = useState({});
  useEffect(() => {
    if (testSummary) {
      setSummaryChartData({
        labels: ['Successful', 'Failed'],
        datasets: [{
          label: 'Success vs Failure',
          data: [
            testSummary.successfulRequests,
            testSummary.failedRequests
          ],
          backgroundColor: [
            'rgba(75, 192, 192, 0.2)', // Green for Successful Requests
            'rgba(255, 159, 64, 0.2)' // Orange for Failed Requests
          ],
          borderColor: [
            'rgba(75, 192, 192, 1)',
            'rgba(255, 159, 64, 1)'
          ],
          borderWidth: 1
        }]
      });
    }
  }, [testSummary]);

  // State for detailed chart data
  const [chartData, setChartData] = useState({});
  useEffect(() => {
    if (testResults && testResults.details) {
      const labels = testResults.details.map(detail => detail.testNumber);
      const data = testResults.details.map(detail => detail.responseTime);

      setChartData({
        labels: labels,
        datasets: [
          {
            label: 'Response Time',
            data: data,
            borderColor: ['rgba(75, 192, 192, 1)'],
            borderWidth: 2,
            fill: false,
          },
        ],
      });
    }
  }, [testResults]);


  return (
    <div>
      <nav className="navbar">
        <div className="main-logo"></div>

        {emailVerifiedMsg ? (<div className="message warn"><span>{emailVerifiedMsg}</span></div>):(<h3>Hey, {userName}</h3>)}

        <button onClick={handleLogout} className="wbutton secondary small">
          Logout
        </button>
      </nav>
      <div className="dashboard">

        {/* Tab Navigation */}
        <div className="tab-nav">
          <button disabled={discoveryRunning} className={`nav-item ${activeTab === 'tab1' ? 'active' : ''}`} onClick={() => setActiveTab('tab1')}>Load testing</button>
          <button className={`nav-item ${activeTab === 'tab2' ? 'active' : ''}`} onClick={() => setActiveTab('tab2')}>Discover API</button>
        </div>
        {/* Tab Content */}
        {activeTab === 'tab1' && (
          <div className="tab-content active">

            <div className="inputs">
              <div className="description">
                <p>Load test your APIs, ensuring they operate seamlessly under pressure.</p>
              </div>
              <input
                type="text"
                value={apiHost}
                onChange={(e) => setApiHost(e.target.value)}
                placeholder="API Host (e.g. https://api.example.com)"
                onKeyDown={handleKeyDown}
              />
              <input
                type="text"
                value={endpointUrl}
                onChange={(e) => setEndpointUrl(e.target.value)}
                placeholder="Endpoint URL (e.g. /api/v1/test)"
                onKeyDown={handleKeyDown}
              />
              <label htmlFor="requestMethod">Test Method
                <span className="info-icon"
                  onMouseEnter={() => setShowInfo(true)}
                  onMouseLeave={() => setShowInfo(false)}
                >
                  <svg width="20px" height="20px" viewBox="-0.5 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 21.5C17.1086 21.5 21.25 17.3586 21.25 12.25C21.25 7.14137 17.1086 3 12 3C6.89137 3 2.75 7.14137 2.75 12.25C2.75 17.3586 6.89137 21.5 12 21.5Z" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M12.9309 8.15005C12.9256 8.39231 12.825 8.62272 12.6509 8.79123C12.4767 8.95974 12.2431 9.05271 12.0008 9.05002C11.8242 9.04413 11.6533 8.98641 11.5093 8.884C11.3652 8.7816 11.2546 8.63903 11.1911 8.47415C11.1275 8.30927 11.1139 8.12932 11.152 7.95675C11.19 7.78419 11.278 7.6267 11.405 7.50381C11.532 7.38093 11.6923 7.29814 11.866 7.26578C12.0397 7.23341 12.2192 7.25289 12.3819 7.32181C12.5446 7.39072 12.6834 7.506 12.781 7.65329C12.8787 7.80057 12.9308 7.97335 12.9309 8.15005ZM11.2909 16.5301V11.1501C11.2882 11.0556 11.3046 10.9615 11.3392 10.8736C11.3738 10.7857 11.4258 10.7057 11.4922 10.6385C11.5585 10.5712 11.6378 10.518 11.7252 10.4822C11.8126 10.4464 11.9064 10.4286 12.0008 10.43C12.094 10.4299 12.1863 10.4487 12.272 10.4853C12.3577 10.5218 12.4352 10.5753 12.4997 10.6426C12.5642 10.7099 12.6143 10.7895 12.6472 10.8767C12.6801 10.9639 12.6949 11.0569 12.6908 11.1501V16.5301C12.6908 16.622 12.6727 16.713 12.6376 16.7979C12.6024 16.8828 12.5508 16.96 12.4858 17.025C12.4208 17.09 12.3437 17.1415 12.2588 17.1767C12.1738 17.2119 12.0828 17.23 11.9909 17.23C11.899 17.23 11.8079 17.2119 11.723 17.1767C11.6381 17.1415 11.5609 17.09 11.4959 17.025C11.4309 16.96 11.3793 16.8828 11.3442 16.7979C11.309 16.713 11.2909 16.622 11.2909 16.5301Z" fill="#000000" />
                  </svg>
                  {showInfo && (
                    <div className="info-tooltip">
                      Which HTTP method will be tested.
                    </div>
                  )}
                </span>
              </label>
              <select
                value={requestMethod}
                onChange={(e) => setRequestMethod(e.target.value)}
                onKeyDown={handleKeyDown}
              >
                <option value="GET">GET</option>
                <option value="POST">POST</option>
                <option value="PUT">PUT</option>
                <option value="DELETE">DELETE</option>
              </select>
              <label htmlFor="statusCode">Success HTTP code
                <span className="info-icon"
                  onMouseEnter={() => setShowInfo(true)}
                  onMouseLeave={() => setShowInfo(false)}>
                  <svg width="20px" height="20px" viewBox="-0.5 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 21.5C17.1086 21.5 21.25 17.3586 21.25 12.25C21.25 7.14137 17.1086 3 12 3C6.89137 3 2.75 7.14137 2.75 12.25C2.75 17.3586 6.89137 21.5 12 21.5Z" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M12.9309 8.15005C12.9256 8.39231 12.825 8.62272 12.6509 8.79123C12.4767 8.95974 12.2431 9.05271 12.0008 9.05002C11.8242 9.04413 11.6533 8.98641 11.5093 8.884C11.3652 8.7816 11.2546 8.63903 11.1911 8.47415C11.1275 8.30927 11.1139 8.12932 11.152 7.95675C11.19 7.78419 11.278 7.6267 11.405 7.50381C11.532 7.38093 11.6923 7.29814 11.866 7.26578C12.0397 7.23341 12.2192 7.25289 12.3819 7.32181C12.5446 7.39072 12.6834 7.506 12.781 7.65329C12.8787 7.80057 12.9308 7.97335 12.9309 8.15005ZM11.2909 16.5301V11.1501C11.2882 11.0556 11.3046 10.9615 11.3392 10.8736C11.3738 10.7857 11.4258 10.7057 11.4922 10.6385C11.5585 10.5712 11.6378 10.518 11.7252 10.4822C11.8126 10.4464 11.9064 10.4286 12.0008 10.43C12.094 10.4299 12.1863 10.4487 12.272 10.4853C12.3577 10.5218 12.4352 10.5753 12.4997 10.6426C12.5642 10.7099 12.6143 10.7895 12.6472 10.8767C12.6801 10.9639 12.6949 11.0569 12.6908 11.1501V16.5301C12.6908 16.622 12.6727 16.713 12.6376 16.7979C12.6024 16.8828 12.5508 16.96 12.4858 17.025C12.4208 17.09 12.3437 17.1415 12.2588 17.1767C12.1738 17.2119 12.0828 17.23 11.9909 17.23C11.899 17.23 11.8079 17.2119 11.723 17.1767C11.6381 17.1415 11.5609 17.09 11.4959 17.025C11.4309 16.96 11.3793 16.8828 11.3442 16.7979C11.309 16.713 11.2909 16.622 11.2909 16.5301Z" fill="#000000" />
                  </svg>
                  {showInfo && (
                    <div className="info-tooltip">
                      Which response HTTP code is considered as success for this test
                    </div>
                  )}
                </span>
              </label>
              <select
                id="statusCode"
                value={selectedStatusCode}
                onKeyDown={handleKeyDown}
                onChange={(e) => setSelectedStatusCode(e.target.value)}
              >
                <option value="" disabled>Select Status Code</option>
                <option value="200">200 OK</option>
                <option value="201">201 Created</option>
                <option value="202">202 Accepted</option>
                <option value="204">204 No Content</option>
                <option value="400">400 Bad Request</option>
                <option value="401">401 Not Allowed</option>
                <option value="403">403 Fobitten</option>
                <option value="404">404 Not Found</option>
                <option value="500">500 Server Error</option>
                {/* Add more status codes as needed */}
              </select>
              <div className="flex-between">
                <label htmlFor="concurrentUsers">Concurrent clients
                  <span className="info-icon"
                    onMouseEnter={() => setShowInfo(true)}
                    onMouseLeave={() => setShowInfo(false)}>
                    <svg width="20px" height="20px" viewBox="-0.5 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12 21.5C17.1086 21.5 21.25 17.3586 21.25 12.25C21.25 7.14137 17.1086 3 12 3C6.89137 3 2.75 7.14137 2.75 12.25C2.75 17.3586 6.89137 21.5 12 21.5Z" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M12.9309 8.15005C12.9256 8.39231 12.825 8.62272 12.6509 8.79123C12.4767 8.95974 12.2431 9.05271 12.0008 9.05002C11.8242 9.04413 11.6533 8.98641 11.5093 8.884C11.3652 8.7816 11.2546 8.63903 11.1911 8.47415C11.1275 8.30927 11.1139 8.12932 11.152 7.95675C11.19 7.78419 11.278 7.6267 11.405 7.50381C11.532 7.38093 11.6923 7.29814 11.866 7.26578C12.0397 7.23341 12.2192 7.25289 12.3819 7.32181C12.5446 7.39072 12.6834 7.506 12.781 7.65329C12.8787 7.80057 12.9308 7.97335 12.9309 8.15005ZM11.2909 16.5301V11.1501C11.2882 11.0556 11.3046 10.9615 11.3392 10.8736C11.3738 10.7857 11.4258 10.7057 11.4922 10.6385C11.5585 10.5712 11.6378 10.518 11.7252 10.4822C11.8126 10.4464 11.9064 10.4286 12.0008 10.43C12.094 10.4299 12.1863 10.4487 12.272 10.4853C12.3577 10.5218 12.4352 10.5753 12.4997 10.6426C12.5642 10.7099 12.6143 10.7895 12.6472 10.8767C12.6801 10.9639 12.6949 11.0569 12.6908 11.1501V16.5301C12.6908 16.622 12.6727 16.713 12.6376 16.7979C12.6024 16.8828 12.5508 16.96 12.4858 17.025C12.4208 17.09 12.3437 17.1415 12.2588 17.1767C12.1738 17.2119 12.0828 17.23 11.9909 17.23C11.899 17.23 11.8079 17.2119 11.723 17.1767C11.6381 17.1415 11.5609 17.09 11.4959 17.025C11.4309 16.96 11.3793 16.8828 11.3442 16.7979C11.309 16.713 11.2909 16.622 11.2909 16.5301Z" fill="#000000" />
                    </svg>
                    {showInfo && (
                      <div className="info-tooltip">
                        Number of concurrent clients to simulate.
                      </div>
                    )}
                    :{concurrentUsers}
                  </span>
                </label>
                <div >
                  {concurrentUsers > 100 ? <span className="link-button" onClick={toggleContact}>Increase limits.</span> : null}
                </div>
              </div>

              {showContact && (
                <div className="popup">
                  <div className="popup-content">Contact <strong>contact@wenture.io</strong> for quota increase.</div>
                  <button onClick={toggleContact} className="wbutton secondary">Close</button>
                </div>
              )}


              <input
                type="range"
                value={concurrentUsers}
                onChange={(e) => setConcurrentUsers(e.target.value)}
                min="1"
                max={maxConcurrency}
                onKeyDown={handleKeyDown}
                style={{
                  '--thumb-color': calculateThumbColor(concurrentUsers, maxConcurrency)
                }}
              />
              <div>

              </div>

              {warningAcknowledged ? (
                <button className="wbutton run-tests-btn" onClick={handleRunTests} disabled={!apiHost || emailVerifiedMsg || loading || cooldown > 0}>
                  {loading ? 'Running...' : (cooldown > 0 ? `Run Test (cooldown, ${cooldown}sec)` : 'Run Test')}
                </button>
              ) : (
                <button className="wbutton warning" onClick={openWarning}>Acknowledge</button>
              )}

              {showWarning && (
                <div className="popup">
                  <div dangerouslySetInnerHTML={{ __html: strings.en.usage_warn }}></div>
                  <button onClick={acknowledgeWarning} className="wbutton secondary">Acknowledge</button>
                </div>
              )}

              {concurrentUsers > 50 ? <p>High concurrent users may take longer to finish</p> : <p className="hidden">i</p>}
              {errorMessage ? <p style={{ color: "red" }}>{errorMessage}</p> : null}

            </div>

            <div className="results-container" style={{ opacity: loading ? 0.4 : 1 }}>
              <div className="results">
                {testSummary ? (
                  <div className="test-summary">
                    <h2>Test Summary</h2>
                    <p>
                      <strong>Average Response Time:</strong> {testSummary.averageResponseTime} ms
                      <span className="info-icon"
                        onMouseEnter={() => setShowInfo(true)}
                        onMouseLeave={() => setShowInfo(false)}>
                        <svg width="20px" height="20px" viewBox="-0.5 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M12 21.5C17.1086 21.5 21.25 17.3586 21.25 12.25C21.25 7.14137 17.1086 3 12 3C6.89137 3 2.75 7.14137 2.75 12.25C2.75 17.3586 6.89137 21.5 12 21.5Z" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                          <path d="M12.9309 8.15005C12.9256 8.39231 12.825 8.62272 12.6509 8.79123C12.4767 8.95974 12.2431 9.05271 12.0008 9.05002C11.8242 9.04413 11.6533 8.98641 11.5093 8.884C11.3652 8.7816 11.2546 8.63903 11.1911 8.47415C11.1275 8.30927 11.1139 8.12932 11.152 7.95675C11.19 7.78419 11.278 7.6267 11.405 7.50381C11.532 7.38093 11.6923 7.29814 11.866 7.26578C12.0397 7.23341 12.2192 7.25289 12.3819 7.32181C12.5446 7.39072 12.6834 7.506 12.781 7.65329C12.8787 7.80057 12.9308 7.97335 12.9309 8.15005ZM11.2909 16.5301V11.1501C11.2882 11.0556 11.3046 10.9615 11.3392 10.8736C11.3738 10.7857 11.4258 10.7057 11.4922 10.6385C11.5585 10.5712 11.6378 10.518 11.7252 10.4822C11.8126 10.4464 11.9064 10.4286 12.0008 10.43C12.094 10.4299 12.1863 10.4487 12.272 10.4853C12.3577 10.5218 12.4352 10.5753 12.4997 10.6426C12.5642 10.7099 12.6143 10.7895 12.6472 10.8767C12.6801 10.9639 12.6949 11.0569 12.6908 11.1501V16.5301C12.6908 16.622 12.6727 16.713 12.6376 16.7979C12.6024 16.8828 12.5508 16.96 12.4858 17.025C12.4208 17.09 12.3437 17.1415 12.2588 17.1767C12.1738 17.2119 12.0828 17.23 11.9909 17.23C11.899 17.23 11.8079 17.2119 11.723 17.1767C11.6381 17.1415 11.5609 17.09 11.4959 17.025C11.4309 16.96 11.3793 16.8828 11.3442 16.7979C11.309 16.713 11.2909 16.622 11.2909 16.5301Z" fill="#000000" />
                        </svg>
                        {showInfo && (
                          <div className="info-tooltip-large">
                            <p>
                              A good average API response time often depends on the complexity of the operation, the amount of data being transferred, and the expectations of the user experience. For a medium-sized operation in a user-facing application, you would typically aim for:
                            </p>
                            <ul>
                              <li>
                                <b>Fast Response</b>: below 100ms, users perceive the response as almost instantaneous.
                              </li>
                              <li>
                                <b>Acceptable Response</b>: 100-300ms is acceptable, users experience a slight perceptible delay.
                              </li>
                              <li>
                                <b>Noticeable Delay</b>: 300-500ms starts to introduce a noticeable delay, but it may still be acceptable for more complex operations.
                              </li>
                              <li>
                                <b>Potentially Problematic</b>: Above 500ms could be perceived as slow by the user.
                              </li>
                            </ul>
                          </div>
                        )}
                      </span>
                    </p>

                    <p>
                      <strong>Successful Tests:</strong> {testSummary.successfulRequests}
                    </p>
                    <p>
                      <strong>Failed Tests:</strong> {testSummary.failedRequests}
                    </p>
                  </div>
                ) : (
                  <p>Test results will be displayed here.</p>
                )}
              </div>

              <div className="chart-container">
                {chartData.labels && chartData.labels.length ? (
                  <Line
                    key={chartKey}
                    data={chartData}
                    options={{
                      responsive: true,
                      scales: {
                        y: {
                          beginAtZero: true,
                          title: {
                            display: true,
                            text: 'Response Time',
                          },
                        },
                        x: {
                          title: {
                            display: true,
                            text: 'Test Number',
                          },
                        },
                      },
                    }}
                  />
                ) : (
                  <p></p>
                )}

                {summaryChartData.labels && summaryChartData.labels.length ? (
                  <Bar
                    data={summaryChartData}
                    options={{
                      responsive: true,
                      scales: {
                        y: {
                          beginAtZero: true,
                          title: {
                            display: true,
                            text: 'Nr of Tests',
                          },
                        },
                        x: {
                          title: {
                            display: true,
                            text: 'Metrics',
                          },
                        },
                      },
                    }}
                  />
                ) : (
                  <p></p>
                )}
              </div>

              {testResults && testResults.details && (
                <p className="link-button" onClick={handleToggleLog}>
                  {showLog ? 'Hide' : 'Show'} Full Log
                </p>
              )}

              {showLog && testResults && testResults.details && ( // Conditional rendering based on showLog
                <div className="log-container">
                  <button onClick={copyToClipboard}>📋copy</button>
                  {copySuccess && <div style={{ color: 'green' }}>{copySuccess}</div>}
                  <pre>{JSON.stringify(testResults.details, null, 2)}</pre>
                </div>
              )}
            </div>

          </div>
        )}
        {activeTab === 'tab2' && <div className="tab-content"><DiscoverTab /></div>}
      </div>
    </div>

  );
}

export default Dashboard;
