import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { registerWithCredentials } from '../core/user/service';
import { selectAuthError } from '../store/slices/stateSlice';
import { strings } from '../assets/strings'

function SignupForm({ onLoginRedirect }) {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  let error = useSelector(selectAuthError);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const result = await registerWithCredentials(email, password, dispatch);
    setLoading(false);
    // The navigation will be handled by the parent component through a callback.
  };

  const openTerms = () => {
    setShowTerms(true);
  };

  const closeTerms = () => {
    setShowTerms(false);
    setTermsAccepted(false);
  };

  const acknowledgeTerms = () => {
    setTermsAccepted(true);
    setShowTerms(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <input
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Email"
        required
      />
      <input
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        placeholder="Password"
        required
      />

      <div className="terms-and-conditions" onClick={openTerms}>
        <input
          type="checkbox"
          checked={termsAccepted}
          onChange={(e) => setTermsAccepted(e.target.checked)}
        />
        <span >I agree to the terms & conditions</span>
      </div>
      {showTerms && (
        <div className="popup scrollable">
          <div className="wbutton secondary small" onClick={closeTerms}>close</div>
          <div dangerouslySetInnerHTML={{ __html: strings.en.terms_and_conditions }}></div>
          <button onClick={acknowledgeTerms} className="wbutton secondary">Acknowledge</button>
        </div>
      )}
      <button className="wbutton main" type="submit" disabled={loading || !termsAccepted}>
        {loading ? 'Loading...' : 'Signup'}
      </button>
      {error && <p className="error-msg">{error}</p>}
    </form>
  );
}

export default SignupForm;
