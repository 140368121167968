import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../auth/auth-context';

function PrivateRoute({ children }) {
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  if (!currentUser) {
    navigate('/authenticate');
    return null;
  }
  
  return children;
}

export default PrivateRoute;
